import en from './translations/en'
import nl from './translations/nl'
import de from './translations/de'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: en,
    nl: nl,
    de: de,
  },
}))
